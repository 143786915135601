<template>
  <header class="s-modal-header">
    <span class="display-4 bold blue">Change image</span>
  </header>
  <main style="height: 300px">
    <section v-if="!displayCropper">
      <image-upload @change="onImageUpload"></image-upload>
      <footer class="s-flex-horizontal-right">
        <span class="display-9">Drop image here or click to select file</span>
      </footer>
    </section>
    <section v-else>
      <section style="height: 240px">
        <image-cropper
          :src="source"
          style="height: 240px; width: 400px"
          ref="cropper"
          :view-mode="0"
          :aspect-ratio="config?.aspectRatio || 1.66667"
          :min-crop-box-width="config?.minWidth || 80"
          :min-crop-box-height="config?.minHeight || 80"
          :max-crop-box-width="config?.maxWidth || 1000"
          :max-crop-box-height="config?.maxHeight || 800"
          @change="() => (this.dirty = true)"
        ></image-cropper>
      </section>
      <section v-if="error">
        <div class="alert alert-danger mb-3" role="alert">
          An error occured when saving your image. Please try again.
        </div>
      </section>
      <footer class="s-flex-horizontal-right" style="padding: 8px">
        <i class="fas fa-trash icon-button" @click="clear()"></i>
      </footer>
    </section>
  </main>
  <footer class="s-modal-footer">
    <button class="small cancel" @click.prevent="onClose()">Cancel</button>
    <ButtonWithLoadingIcon
      class="orange small"
      @click="save"
      :loading="loading"
      :disabled="!dirty || (!source && config?.required)"
    >
      Save
    </ButtonWithLoadingIcon>
  </footer>
</template>

<script>
import ImageUpload from './ImageUpload.vue'
import ImageCropper from './ImageCropper.vue'
import ButtonWithLoadingIcon from '../ButtonWithLoadingIcon.vue'

export default {
  name: 'CroppedImageView',
  components: { ButtonWithLoadingIcon, ImageCropper, ImageUpload },
  props: ['src', 'onSave', 'config', 'onClose'],
  inheritAttrs: false,
  data() {
    return {
      model: {},
      dirty: false,
      source: undefined,
      displayCropper: false,
      loading: false,
      error: false,
    }
  },
  async mounted() {
    if (this.src) this.source = this.src
    // Check if file actually exists
    if (this.source) this.displayCropper = await this.imageExists(this.source)
  },
  methods: {
    imageExists(url) {
      return new Promise((resolve, reject) => {
        const image = new Image()
        image.onload = () => {
          resolve(true)
        }
        image.onerror = () => {
          reject(false)
        }
        image.src = url
      })
    },
    async save() {
      this.loading = true
      let dataUrl = undefined

      if (this.source) {
        dataUrl = this.$refs.cropper.cropper
          .getCroppedCanvas()
          .toDataURL('image/png')
      }
      try {
        await this.onSave(dataUrl)
      } catch (error) {
        console.log(error)
        this.error = true
      } finally {
        this.loading = false
      }
    },
    onImageUpload(payload) {
      this.source = payload
      this.displayCropper = true
      this.dirty = true
    },
    clear() {
      this.source = undefined
      this.displayCropper = false
      this.dirty = true
    },
  },
}
</script>

<style scoped></style>
