import { createApp } from 'vue'
import CroppedImageModal from './CroppedImageModal.vue'

function dataURItoBlob(dataURI) {
  const binary = atob(dataURI.split(',')[1])
  const array = []
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i))
  }
  return new Blob([new Uint8Array(array)], { type: 'image/png' })
}

//Function that inserts an array of File objects inside a input type file, because HTMLInputElement.files cannot be setted directly
function FileListItems(file_objects) {
  const new_input = new DataTransfer()
  for (let i = 0, size = file_objects.length; i < size; ++i) {
    new_input.items.add(file_objects[i])
  }
  return new_input.files
}

export default class CroppedImageUpload {
  constructor() {
    const self = this
    this.app = undefined

    // For the web, we use the CroppedImageModal component, to let the user crop their image in the browser.
    $('body:not(.mobile_application) .cropped-image-upload-button').on(
      'click',
      (event) => {
        event.preventDefault()

        const inputId = event.currentTarget.htmlFor
        const input = document.getElementById(inputId)
        const minHeight = input.dataset.minHeight
        const minWidth = input.dataset.minWidth
        const preferredHeight = input.dataset.preferredHeight
        const preferredWidth = input.dataset.preferredWidth
        const aspectRatio = input.dataset.aspectRatio
        const croppingRatio = input.dataset.croppingRatio
        const required = input.dataset.required

        const existingImage = document.getElementById(`${inputId}_image`)
        const src = existingImage.src
        this.app = createApp(
          {
            template: '<cropped-image-modal/>',
            components: { CroppedImageModal },
          },
          {
            src,
            config: {
              minHeight,
              minWidth,
              preferredHeight,
              preferredWidth,
              aspectRatio,
              croppingRatio,
              required,
            },
            onSave: async (payload) => {
              let file
              if (payload) {
                // Update input value.
                existingImage.src = payload
                $(existingImage).removeClass('d-none')

                const blob = dataURItoBlob(payload)
                //Use the Blob to create a File Object
                file = new File([blob], 'img.png', {
                  type: 'image/png',
                  lastModified: new Date().getTime(),
                })
                const images = [file]
                const filelist = new FileListItems(images)
                input.files = filelist
                input.dispatchEvent(new Event('change', { bubbles: true }))
              } else {
                input.value = null
                input.files = null
                input.dispatchEvent(new Event('change', { bubbles: true }))
              }

              // Silently try to submit the form with only the image (Or image_clear when the user deleted their image.)
              // so the user will not have to click the submit button.
              // This will fail when the related form or model does not allow saving of a single field,
              // so we wrap it in a try catch block.
              try {
                const form = input.form
                const formData = new FormData()
                formData.append(
                  'csrfmiddlewaretoken',
                  form.elements['csrfmiddlewaretoken'].value
                )
                if (file) formData.append(input.name, file)
                else formData.append(`${input.name}_clear`, 'on')
                await fetch(form.action, {
                  method: form.method,
                  body: formData,
                })
              } catch (error) {
                console.error(error)
              }
              return
            },
            onClose: () => {
              self.app.unmount()
            },
          }
        )
        const component = `#image-upload-dialog-${inputId}`
        self.app.mount(component)
      }
    )

    // For mobile applications, we use the native file input dialog and let the user crop their image there.
    // We still want to show the image after the user has selected it.
    $('.cropped-image-upload input').on('change', (event) => {
      const input = event.currentTarget
      const inputId = input.id
      const inputClearCheckBox = document.getElementById(`${inputId}_clear`)
      const clearButtonContainer = document.getElementById(
        `${input.id}-clear-button-container`
      )
      const image = document.getElementById(`${input.id}_image`)
      if (input.files && input.files.length > 0) {
        const file = input.files[0]
        const url = URL.createObjectURL(file)
        image.src = url

        $(image).removeClass('d-none')
        const noImageSelectedDiv = $(`#${input.id}_no_image`)
        noImageSelectedDiv.addClass('d-none')
        $(clearButtonContainer).removeClass('d-none')
        inputClearCheckBox.checked = false
      } else {
        image.src = null
        $(image).addClass('d-none')
        $(clearButtonContainer).addClass('d-none')
        inputClearCheckBox.checked = true
        const noImageSelectedDiv = $(`#${input.id}_no_image`)
        noImageSelectedDiv.removeClass('d-none')
      }
    })

    $('.cropped-image-upload-clear-button').on('click', (event) => {
      event.preventDefault()
      const inputId = event.currentTarget.dataset.inputId
      const input = document.getElementById(inputId)
      input.files = null
      input.value = null
      input.files = null
      input.dispatchEvent(new Event('change', { bubbles: true }))
    })
  }
}
